class ActiveSection {
    constructor() {
        this._stageSection();
    }

    _stageSection() {
        // get viewport height
        const getVh = () => {
            const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
            return vh;
        }

        // get viewport width
        const getVw = () => {
            const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
            return vw;
        }

        gsap.utils.toArray('.stage').forEach((stage, index) => {
            const navLinks = gsap.utils.toArray('.menu .menu-item');
            ScrollTrigger.create({
                trigger: stage,
                start: 'top top+=80',
                end: () => `+=${stage.clientHeight+getVh()/1000}`,
                toggleClass: {
                    targets: navLinks[index],
                    className: 'current-menu-item'
                },
            })
        });


    }

}